
export const getVersion = function() { 
    var MAJOR=3;
    var MINOR=1;
    var RELEASE=0;
    var COMMIT="0f78de1f";
    var BUILD="470";
    return MAJOR + "." + MINOR + "." + RELEASE + "." + BUILD + "-" + COMMIT; 
}

export default getVersion;
